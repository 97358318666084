import { Fragment } from 'react';
import Banner from "../../components/Banner/Banner";
import AboutCompany from "../../components/About/AboutCompany";
import OurService from "../../components/About/OurService";
import Testimonials from "../../components/Testimonials/Testimonials";
const Home = props => {
    return (
        <Fragment>
            <Banner />
            <AboutCompany />
            <OurService />
            {/* <Testimonials /> */}
        </Fragment>
    );
};

export default Home;

