import { Fragment } from 'react';
import {
  BrowserRouter as HashRouter,
  Routes,
  Route
} from "react-router-dom";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import Home from "./components/Pages/Home";
import About from "./components/Pages/About";
import GalleryPage from "./components/Pages/Gallery";
import ContactUs from "./components/Pages/ContactUs";

import HydraulicElevators from "./components/Pages/product/HydraulicElevators";
import HospitalElevator from "./components/Pages/product/HospitalElevator";
import PassengerElevator from "./components/Pages/product/PassengerElevator";
import GoodsElevator from "./components/Pages/product/GoodsElevator";
import MRElevator from "./components/Pages/product/MRElevator";
import MRLElevator from "./components/Pages/product/MRLElevator";
import BungalowElevator from "./components/Pages/product/BungalowElevator";

function App() {
  return (
    <HashRouter basename='/' >
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/gallery' element={<GalleryPage />} />
        <Route path='contact-us' element={<ContactUs />} />

        <Route path='hydraulic_elevators' element={<HydraulicElevators />} />
        <Route path='hospital_elevators' element={<HospitalElevator />} />
        <Route path='passenger_elevators' element={<PassengerElevator />} />
        <Route path='goods_elevators' element={<GoodsElevator />} />
        <Route path='mr_elevators' element={<MRElevator />} />
        <Route path='mrl_elevators' element={<MRLElevator />} />
        <Route path='bungalow_elevators' element={<BungalowElevator />} />
      </Routes>
      <Footer />
    </HashRouter>
  );
}

export default App;