import { Fragment } from 'react';
import HeaderSection from '../../Layout/HeaderSection';
import PageFeaturesSection from '../product/PageFeaturesSection'
import GetInTouch from '../../Common/GetInTouch';
import ApplicationsOfOurElevators from '../../Common/ApplicationsOfOurElevators';

const data = require('../../../assets/database/PassengerElevators.json');

const PassengerElevator = props => {

    return (
        <Fragment>
            <HeaderSection title="Passenger Elevators" />
            <PageFeaturesSection page_name="PassengerElevators.json" />

            <section className="text-gray-700 body-font border-t border-gray-200">
                <div className="flex flex-col text-center w-70 p-10">
                    <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-5">Efficient Passenger Elevator</h1>
                </div>
                <div className="container px-5 mx-auto flex flex-wrap">
                    <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
                        <img src='services/content.jpg' />
                    </div>
                    <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                        <div className="flex flex-col mb-10 lg:items-start items-center">
                            <div className="flex-grow">
                                <h2 className="font-medium font-semibold mb-3 text-gray-900 text-lg title-font">Safety and Reliability</h2>
                                <p className="leading-relaxed text-base">Our passenger elevators are built to the highest safety standards, ensuring the safety and security of passengers at all times. With robust construction and advanced safety features, you can trust our elevators to provide reliable performance day in and day out.</p>
                            </div>
                        </div>
                        <div className="flex flex-col mb-10 lg:items-start items-center">
                            <div className="flex-grow">
                                <h2 className="font-medium font-semibold mb-3 text-gray-900 text-lg title-font">Smooth and Comfortable Rides</h2>
                                <p className="leading-relaxed text-base">Experience smooth and comfortable rides with our passenger elevators, designed for quiet operation and minimal vibrations. Whether you're traveling a few floors or many, our elevators provide a comfortable and enjoyable ride experience.</p>
                            </div>
                        </div>
                        <div className="flex flex-col mb-10 lg:items-start items-center">
                            <div className="flex-grow">
                                <h2 className="font-medium font-semibold mb-3 text-gray-900 text-lg title-font">Space-Saving Design</h2>
                                <p className="leading-relaxed text-base">Our passenger elevators are designed with compact footprints, making them suitable for installation in a variety of building settings. Whether you have a small space or need to maximize floor space, we have elevator solutions to meet your needs.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="text-gray-700 body-font border-t border-gray-200">
                <ApplicationsOfOurElevators title="Applications of Our Passenger Elevators" applications={data.applications_of_our_elevators} />
                <GetInTouch title="Get in Touch:" description={data.get_in_touch} />
            </section>

        </Fragment>
    );
};

export default PassengerElevator;