import { Fragment } from 'react';

const ServiceCard = ({ icon, title, details }) => {
    return (
        <Fragment>
            <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                <div className="mb-9 rounded-[20px] bg-white p-10 shadow-2 hover:shadow-lg dark:bg-dark-2 md:px-7 xl:px-10">

                    <h4 className="mb-[14px] text-2xl font-semibold text-dark dark:text-white">
                        {title}
                    </h4>
                    <p className="text-body-color dark:text-dark-6">{details}</p>
                </div>
            </div>
        </Fragment>
    );
};

const OurService = props => {

    const Service = () => {

        return (
            <section className="bg-gray-50 dark:bg-dark lg:pb-[90px]  pb-12 pt-10">
                <div className="container mx-auto">
                    <div className="-mx-4 flex flex-wrap">
                        <div className="w-full px-4">
                            <div className="max-w-2xl mb-12 mx-auto text-center">
                                <h2 className="mb-3 text-3xl font-bold leading-[1.2] text-dark dark:text-white sm:text-4xl md:text-[40px]">
                                    Our Services
                                </h2>
                                <p className="text-base text-body-color dark:text-dark-6">
                                    our team of skilled professionals is dedicated to providing exceptional service every step of the way. We pride ourselves on being responsive, reliable, and easy to work with
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="-mx-4 flex flex-wrap">
                        <ServiceCard
                            title="Residential Elevators"
                            details="Elevate your home living experience with our customizable, space-saving, and stylish elevator options designed to seamlessly integrate into your residential space."
                            icon=<img src="icon/design.svg" width="36" height="36" />
                        />
                        <ServiceCard
                            title="Commercial Elevators"
                            details="Commercial elevator the accessibility, efficiency, and sophistication of your commercial space with our range of customizable, reliable, and innovative commercial elevators."
                            icon=<img src="icon/design.svg" width="36" height="36" />
                        />
                        <ServiceCard
                            title="Industrial Elevators"
                            details="Goods elevator designed to transport heavy loads, machinery, and equipment within industrial settings such as factories, warehouses and manufacturing plants. "
                            icon=<img src="icon/design.svg" width="36" height="36" />

                        />
                        <ServiceCard
                            title="Accessibility Lifts"
                            details="where we specialize in providing accessibility lifts designed to enhance mobility and independence for individuals with disabilities or limited mobility."
                            icon=<img src="icon/design.svg" width="36" height="36" />
                        />
                        <ServiceCard
                            title="Modernization and Upgrades"
                            details="Our modernization and upgrade services are designed to enhance the performance, reliability, and safety of your elevators while extending their lifespan."
                            icon=<img src="icon/design.svg" width="36" height="36" />
                        />
                        <ServiceCard
                            title="Maintenance and Support"
                            details="Maintenance option for a low-maintenance elevator model to reduce the need for frequent servicing and repairs"
                            icon=<img src="icon/design.svg" width="36" height="36" />
                        />
                    </div>
                </div>
            </section>
        );
    };

    return (
        <Fragment>
            <Service />
        </Fragment>
    );
};

export default OurService;

