import { Fragment } from 'react';

const ApplicationsOfOurElevators = props => {
    const Applications = props.applications;
    return (
        <Fragment>
            <div className="container px-5 py-24 mx-auto">
                <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">{props.title}</h1>
                </div>

                <div className="flex flex-wrap -m-4">
                    {Applications.map((row) =>
                        <div className="xl:w-1/3 md:w-1/2 p-4">
                            <div className="border border-gray-300 p-6 rounded-lg">
                                <h2 className="font-medium font-semibold mb-2 text-gray-900 text-lg title-font">{row.name}</h2>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default ApplicationsOfOurElevators;