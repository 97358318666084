import { Fragment } from 'react';
import HeaderSection from '../../Layout/HeaderSection';
import PageFeaturesSection from '../product/PageFeaturesSection'

import GetInTouch from '../../Common/GetInTouch';
import ApplicationsOfOurElevators from '../../Common/ApplicationsOfOurElevators';
const data = require('../../../assets/database/HydraulicElevators.json');
const HydraulicElevators = props => {
    return (
        <Fragment>
            <HeaderSection title="Hydraulic Elevators" />
            <PageFeaturesSection page_name="HydraulicElevators.json" />
            <section className="text-gray-700 body-font border-t border-gray-200">
                <ApplicationsOfOurElevators title="Applications of Our Passenger Elevators" applications={data.applications_of_our_elevators} />
                <GetInTouch title="Get in Touch:" description={data.get_in_touch} />
            </section>
        </Fragment>
    );
};

export default HydraulicElevators;