import { Fragment } from 'react';
const PageFeaturesSection = props => {

    const data = require('../../../assets/database/' + props.page_name);

    const FeaturesData = data.features_section;
    return (
        <Fragment>
            <section className="text-gray-700 body-font border-t border-gray-200">
                <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-col text-center w-full mb-20">
                        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900"> {data.title}</h1>
                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">{data.description}</p>
                        <a style={{ backgroundColor: "#d21d33" }} href={data.download_brochure} target='__blank' className="flex mx-auto mt-16 text-white  border-0 py-2 px-8 focus:outline-none hover:bg-blue-950 rounded text-lg">Download Brochure</a>
                    </div>
                    <div className="flex flex-wrap -m-4">
                        {FeaturesData.map((row) =>
                            <div className="p-4 md:w-1/3">
                                <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                                    <div className="flex items-center mb-3">
                                        <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                                <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                                            </svg>
                                        </div>
                                        <h2 className="text-gray-900 text-lg title-font font-medium">{row.title}</h2>
                                    </div>
                                    <div className="flex-grow">
                                        <p className="leading-relaxed text-base">{row.description}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default PageFeaturesSection;