import { Fragment, useState } from 'react';
import axios from 'axios'
import { Alert } from "@material-tailwind/react";

const ContactUs = props => {
    const [message, setMessage] = useState(null);
    const [visibleAlert, setVisibleAlert] = useState(false);

    const submitHandler = async (event) => {
        event.preventDefault();
        setMessage(null);
        try {
            const inputs = event.target.elements;
            const data = {};
            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].name) {
                    data[inputs[i].name] = inputs[i].value;
                }
            }
            axios({
                url: 'https://4maticelevators.com/api/contact-us.php',
                data: data,
                method: "POST",
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(res => {
                    setMessage(res.data.message);
                    setVisibleAlert(true)
                    setTimeout(() => {
                        setVisibleAlert(false)
                        setMessage(null);
                    }, 5000);
                })
                .catch(err => {
                    console.log(err);
                });
        } catch (error) {
            console.log(error.message)
        }
    }

    const ContactUsPage = () => {
        return (
            <div>
                <section className="text-gray-700 body-font relative">
                    <div className="container px-5 py-24 mx-auto">
                        <div className="flex flex-col text-center w-full mb-12">
                            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
                                Contact Us
                            </h1>
                            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                                Thank you for your interest in 4Matic Elevator Technology. We're here to assist you with all your lift installation, maintenance, and modernization needs. Please feel free to contact us using the information below, or fill out the contact form, and we'll get back to you as soon as possible.
                            </p>
                        </div>
                        <form
                            className="contact-form"
                            onSubmit={submitHandler}
                            method='post'
                        >

                            <div className="lg:w-1/2 md:w-2/3 mx-auto">
                                <div show={visibleAlert} className='pb-10'>{message && <Alert color="green" className="flex flex-wrap -m-2">{message}</Alert>}</div>

                                <div className="flex flex-wrap -m-2">
                                    <div className="p-2 w-1/2">
                                        <div className="relative">
                                            <label for="first_name" className="leading-7 text-sm text-gray-600">
                                                First Name
                                            </label>

                                            <input
                                                type="text"
                                                placeholder="Please enter first name"
                                                name="first_name"
                                                className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 w-1/2">
                                        <div className="relative">
                                            <label
                                                for="last_name"
                                                className="leading-7 text-sm text-gray-600"
                                            >
                                                Last Name
                                            </label>
                                            <input
                                                placeholder="Please enter last name"
                                                type="text"
                                                id="last_name"
                                                name="last_name"
                                                className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 w-1/2">
                                        <div className="relative">
                                            <label for="mobile" className="leading-7 text-sm text-gray-600">
                                                Mobile
                                            </label>

                                            <input
                                                type="text"
                                                id="mobile"
                                                placeholder="Please enter mobile"
                                                name="mobile"
                                                className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 w-1/2">
                                        <div className="relative">
                                            <label
                                                for="email"
                                                className="leading-7 text-sm text-gray-600"
                                            >
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                placeholder="Please enter email"
                                                name="email"
                                                className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 w-full">
                                        <div className="relative">
                                            <label
                                                for="message"
                                                className="leading-7 text-sm text-gray-600"
                                            >
                                                Message
                                            </label>
                                            <textarea
                                                placeholder="Please enter message"
                                                id="message"
                                                name="message"
                                                className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                                                required
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="p-2 w-full">
                                        <input
                                            type="submit"
                                            value="Submit"
                                            className="flex mx-auto text-white bg-black border-0 py-2 px-8 focus:outline-none hover:bg-blue-950 rounded text-lg"
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        );
    };

    return (
        <Fragment>
            <ContactUsPage />
        </Fragment>
    );
};

export default ContactUs;