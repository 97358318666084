import { Fragment } from 'react'
const HeaderSection = props => {
    return <Fragment>
        <div className=" flex flex-wrap">
            <div className="w-full px-4 bg-gray-100 ">
                <div className="max-w-2xl mx-auto p-10 text-center">
                    <h2 className="mb-3 text-3xl font-bold leading-[1.2] text-dark dark:text-white sm:text-4xl md:text-[40px]">
                        {props.title}
                    </h2>

                </div>
            </div>
        </div>
    </Fragment >
}
export default HeaderSection;