import { Fragment } from 'react';
import HeaderSection from '../../Layout/HeaderSection';
import PageFeaturesSection from '../product/PageFeaturesSection'

import ApplicationsOfOurElevators from '../../Common/ApplicationsOfOurElevators';
import GetInTouch from '../../Common/GetInTouch';
const data = require('../../../assets/database/BungalowElevators.json');
const BungalowElevator = props => {
    return (
        <Fragment>
            <Fragment>
                <HeaderSection title=" Bungalow Elevators" />
                <PageFeaturesSection page_name="BungalowElevators.json" />
                <section className="text-gray-700 body-font border-t border-gray-200">
                    <ApplicationsOfOurElevators title="Applications of Our Passenger Elevators" applications={data.applications_of_our_elevators} />
                    <GetInTouch title="Get in Touch:" description={data.get_in_touch} />
                </section>
            </Fragment>
        </Fragment>
    );
};

export default BungalowElevator;