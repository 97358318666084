import { Fragment } from 'react';

const AboutCompany = props => {

    const About = () => {

        return (
            <main className="py-6 px-4 sm:p-6 md:py-10 md:px-8">
                <div className="grid grid-cols-1 lg:gap-x-20 lg:grid-cols-2 max-w-7xl  mx-auto  ">
                    <div className="relative p-3 col-start-1 row-start-1 flex flex-col-reverse rounded-lg bg-gradient-to-t from-black/75 via-black/0 sm:bg-none sm:row-start-2 sm:p-0 lg:row-start-1">
                        <h1 className="mt-1 text-lg font-semibold text-white sm:text-slate-900 md:text-2xl dark:sm:text-white">Who Are We?</h1>
                    </div>
                    <div className="grid gap-4 col-start-1 col-end-3 row-start-1 sm:mb-6 sm:grid-cols-4 lg:gap-6 lg:col-start-2 lg:row-end-6 lg:row-span-6 lg:mb-0">
                        <img src="services/why_services.jpg" alt="" className="w-full h-60 object-cover rounded-lg sm:h-52 sm:col-span-2 lg:col-span-full" loading="lazy" />
                        <img src="services/our_customers.jpg" alt="" className="hidden w-full h-52 object-cover rounded-lg sm:block sm:col-span-2 md:col-span-1 lg:row-start-2 lg:col-span-2 lg:h-32" loading="lazy" />
                        <img src="services/our_expertise.jpg" alt="" className="hidden w-full h-52 object-cover rounded-lg md:block lg:row-start-2 lg:col-span-2 lg:h-32" loading="lazy" />
                    </div>


                    <div className="mt-4 col-start-1 row-start-3 self-center sm:mt-0 sm:col-start-2 sm:row-start-2 sm:row-span-2 lg:mt-6 lg:col-start-1 lg:row-start-3 lg:row-end-4">
                        <p className="mt-4 text-sm leading-6 col-start-1">
                            At 4Matic Elevator Technology, we are more than just an elevator company - we're your partners in vertical mobility solutions. With 5 years of experience in the industry, we've established ourselves as leaders in providing innovative, reliable, and safe elevator systems for residential, commercial, and industrial applications.

                        </p>
                        <br />
                        <h1 className="mt-1 text-lg font-semibold text-white sm:text-slate-900 md:text-2xl dark:sm:text-white"> Our Mission</h1>
                        <p className="mt-4 text-sm leading-6 col-start-1">
                            Our mission is simple: to elevate lives by delivering superior vertical transportation solutions that enhance accessibility, convenience, and efficiency in buildings of all kinds. We strive to exceed customer expectations through our commitment to quality, safety, and exceptional service.
                        </p>
                    </div>
                </div>
            </main >
        );
    };

    return (
        <Fragment>
            <About />
        </Fragment>
    );
};

export default AboutCompany;

